<template>
  <div class="loader">
    <Spinner class="spinner" />
    <a
      href="https://www.youtube.com/playlist?list=PLYf-BGGizyN8FySvaNON9bQhz3-3h7ox_"
      target="_blank"
      rel="noopener noreferrer"
      class="last-work nostyle"
    >
      LATEST WORK
    </a>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },
};
</script>

<style>
.loader {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.spinner {
  margin-bottom: 150px;
}
a.nostyle:link {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}
a.nostyle:visited {
  text-decoration: inherit;
  color: inherit;
  cursor: auto;
}
.last-work {
  background-color: #94d900;
  border-radius: 25px;
  padding: 8px 28px;
  font-weight: 900;
  transition: all 0.2s;
  border: 2px solid #94d900;
}
.last-work:hover {
  cursor: url(../assets/pointer.png), auto !important;
  background: transparent;
  color: #94d900 !important;
}
</style>
