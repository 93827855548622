<template>
  <div id="app">
    <transition name="fade">
      <loader v-show="loading"></loader>
    </transition>
    <div class="wrapper">
      <Header />
      <router-view @load="load" @loaded="loaded" />
    </div>

    <div class="line"></div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Loader from "@/components/Loader.vue";

export default {
  components: {
    Header,
    Loader,
  },
  data: () => ({
    loading: true,
    loadedContent: false,
  }),
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
    },
    loaded() {
      if (!this.loadedContent) {
        this.loading = true;
        this.loadedContent = true;
        const self = this;
        setTimeout(() => {
          self.loading = false;
          document.body.classList.remove("no-scroll");
        }, 2000);
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

body,
html {
  font-family: "Open Sans", sans-serif;
  margin: 0;
}

* {
  cursor: url(./assets/cursor.png), auto;
}

a:hover {
  cursor: url(./assets/pointer.png), auto;
}

.no-scroll {
  overflow: hidden;
}

#app {
  min-height: 100vh;
  background-color: #000;
}

.line {
  width: 100vw;
  height: 10px;
  background-color: #94d900;
  position: fixed;
  bottom: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
