<template>
  <div class="home">
    <swiper ref="mySwiper" v-show="loading" :options="swiperOptions">
      <swiper-slide v-for="v in videos" :key="v.video">
        <a :href="v.url" rel="noopener noreferrer" target="_blank">
          <video
            :id="v.video"
            autoplay
            muted
            loop
            playsinline
            @loadeddata="loaded = loaded + 1"
          >
            <source :src="getVideoUrl(v.video)" type="video/mp4" />
          </video>
        </a>
        <div class="infos">
          <p class="title">{{ v.title }}</p>
          <p class="side">{{ v.side }}</p>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  data: () => ({
    loaded: 0,
    loading: false,
    videos: [
      {
        title: `"shaggy" - myth syzer`,
        side: "| prod. scald | june, 2024.",
        url: "https://youtu.be/qsVBdntnIr4",
        video: "shaggy",
      },
      {
        title: `"plus fort" - bekar`,
        side: "| february, 2024.",
        url: "https://youtu.be/Jf5_byj44h4",
        video: "plusfort",
      },
      {
        title: `"bank account" EU tour - 21 Savage`,
        side: "| w/ @arth.w, @at_paris, @vacarme.ooo | november, 2023.",
        url: "https://www.instagram.com/p/CzrCddLsU5E",
        video: "21",
      },
      {
        title: `"passion inside" - PSG carte blanche`,
        side: "| creative production @scaldstudio | november, 2023.",
        url: "https://www.psg.fr/carte-blanche/saisons/saison-8/passion-inside",
        video: "passioninside",
      },
      {
        title: `"off" - train fantome`,
        side: "| directed by dolorain | october, 2023.",
        url: "https://youtu.be/gtYjCd6KBQE",
        video: "off",
      },
      {
        title: `"métro boulot croco" - lacoste x raska`,
        side: "| asst. réal. @charlotte_ds | cadre @thmrc | august, 2023.",
        url: "https://youtu.be/ks3eNBGpQm8",
        video: "metroboulotcroco",
      },
      {
        title: `"ma pièce" - mdns`,
        side: "| co-directed w/ @sketskills, @gu1ps and @hs.unknow | july, 2023.",
        url: "https://youtu.be/THKv426gkYk",
        video: "mapiece",
      },      
      {
        title: `"chéri" - lafleyne & angie`,
        side: "| DOP. @amir_elpaco | DA. @charlotte_ds | march, 2023.",
        url: "https://youtu.be/lJ1HNGUls4Y",
        video: "cheri",
      },
      {
        title: `"promesses" - luni sacks`,
        side: "| december, 2022.",
        url: "https://youtu.be/3VaHD9L11tg",
        video: "promesses",
      },
      {
        title: `"k.d stats" - mike shabb`,
        side: "| november, 2022.",
        url: "https://youtu.be/zXPWY9_noXI",
        video: "kdstats",
      },
      {
        title: `psg x jordan - 21/22 fourth jersey`,
        side: "| prod. scald | february, 2022.",
        url: "https://www.instagram.com/p/CZzR-8MqhXo/",
        video: "psgfourth",
      },
      {
        title: `"everyday" - nasty`,
        side: "| october, 2022.",
        url: "https://youtu.be/xLsKGFZ_8cc",
        video: "everyday",
      },
      {
        title: `"3r+" - 8ruki`,
        side: "| september, 2022.",
        url: "https://youtu.be/OxJUfiBHZjQ",
        video: "3r+",
      },
      {
        title: `"apprends-moi" - mdns`,
        side: "| co-directed w/ @gu1ps, @hs.unknow and mdns | june, 2024.",
        url: "https://youtu.be/ZLbg__gYtxU",
        video: "apprendsmoi",
      },
      {
        title: `"tarif groupé" - hologram lo' & huntrill ft. tedax max`,
        side: "| prod. don dada | june, 2024.",
        url: "https://youtu.be/U8-mwFYlcGA",
        video: "tarifgroupe",
      },
    ],
    swiperOptions: {
      effect: "coverflow",
      mousewheel: {
        invert: true,
      },
      direction: "horizontal",
      preloadImages: true,
      coverflowEffect: {
        rotate: -90,
        depth: 155,
        slideShadows: false,
      },
      slidesPerView: 1,
      loop: true,
      centeredSlides: true,
      breakpoints: {
        576: {
          slidesPerView: 3,
          coverflowEffect: {
            rotate: -20,
            depth: 125,
            slideShadows: false
          },
        },
      },
    },
  }),
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  watch: {
    loaded(val) {
      if (val === this.videos.length) {
        this.loading = true;
        this.$emit("loaded");
        if (window.matchMedia("(max-width: 546px)").matches) {
          this.swiper.slideTo(2, 1000, false);
          this.$nextTick(function () {
            const self = this;
            setTimeout(function () {
              self.swiper.slideTo(1, 1000, false);
            }, 0);
          });
        }
      }
    },
  },
  methods: {
    getVideoUrl(key) {
      const images = require.context("../assets/videos", false, /\.mp4/);
      return images("./" + key + ".mp4");
    },
  },
};
</script>

<style>
.home {
  margin-top: 80px;
  padding-bottom: 25px;
}

a > video:hover {
  cursor: url(../assets/pointer.png), auto;
}

video {
  width: 550px;
  height: auto;
  margin-top: 50px;
}

.swiper-slide-active video {
  width: 750px;
  margin-top: 0;
  margin-left: -85px;
}

.swiper-slide-active .infos {
  visibility: visible;
}

.infos {
  color: #ebebeb;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  width: 750px;
  margin-left: -85px;
}
.title {
  color: #94d900;
  margin-right: 5px;
}

@media (max-width: 992px) {
  video,
  .infos {
    width: 450px;
  }
  .swiper-slide-active video {
    max-width: 600px;
  }
  .home {
    margin-top: 30px;
  }
}
@media (max-width: 768px) {
  .swiper-slide-active video {
    max-width: 500px;
  }
  video,
  .infos {
    width: 350px;
  }
  .infos {
    margin: 0 auto;
    flex-direction: column;
  }
  .side {
    margin: 0;
  }
  .side::first-letter {
    visibility: hidden;
  }
}
@media (max-width: 576px) {
  .home {
    overflow: hidden;
    height: 50vh;
  }

  .swiper-container {
    max-height: 100vh;
  }

  video,
  .swiper-slide-active video {
    width: 100%;
    margin-top: 0;
    margin-left: 0;
  }

  .infos {
    max-width: 375px;
  }
}
</style>
